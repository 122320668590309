:root{
  --black: #000000;
  --neutral900: #090909;
  --neutral700: #1F1F1F;
  --dark-grey: rgba(0, 0, 0, 0.08);
  --white: #ffffff;
  --light-grey: #EBEBEB;
  --grey: #D8D8D8;
  --white-background: rgba(255, 255, 255, 0.85);
  --yellow: #FFEE32;
}
* {
  margin: 0;
  padding-bottom: 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  box-sizing: border-box;
}
body{
  overflow-x: hidden;
  min-height: 100vh;
  background: var(--light-grey);
}
header, main, footer{
  padding: 0 64px;
}
button{
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  padding: 16.5px 24px;
  border-radius: 8px;
  background-color: var(--white-background);
  white-space: nowrap;
}
button, button span{
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  cursor: pointer;
  color: var(--neutral900);
}
button:hover{
  background-color: var(--white);
}
button:active{
  background-color: var(--light-grey);
}
button.white-stroke{
  padding: 15px 34px;
  border: 2px solid var(--neutral700);
  background-color: transparent;
}
button.white-stroke, button.white-stroke span{
  color: var(--neutral700);
}
button.white-stroke:hover{
  background-color: var(--dark-grey);
}
button.white-stroke:active{
  background-color: var(--black);
  color: var(--white);
  border: 2px solid var(--black);
}
a{
  text-decoration: none;
}
a:hover{
  text-decoration: underline;
}
#root{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
.container{
  max-width: 1312px;
  margin: 0 auto;
  position: relative;
}
.bg{
  position: absolute;
  z-index: -1;
}
.bg.big-molecula{
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: url("./assets/big-molecula.png") no-repeat top;
  background-size: 100%;
}
.bg.small-molecula{
  right:0;
  bottom:0;
  width: 482px;
  height: 310px;
  background: url("./assets/small-molecula.png") no-repeat top;
  background-size: 100%;
}
.white-blur-bg{
  background: linear-gradient(168.65deg, rgba(255, 255, 255, 0.9) -2.45%, rgba(255, 255, 255, 0.7) 102.09%);
  box-shadow: inset -5px -5px 0 rgba(255, 255, 255, 0.1), inset 5px 5px 4px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(22.5px);
  border-radius: 40px;
}

/*Header*/
.logo{
  cursor: pointer;
}
header .header-inner{
  width: 100%;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: left;
  z-index: 1;
}
header .logo-wrapper{
  margin-right: calc(50% - 220px - 236px);
}
header .logo{
  width: 236px;
}
header nav{
  display: flex;
  gap: 42px;
  margin-right: auto;
}
header nav a{
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: var(--neutral700);
}
header nav a[data-active="true"]{
  text-decoration: underline;
}
header .additional-buttons{
  display: none;
}

/*Footer*/
footer{
  width: 100%;
  margin-top: auto;
  padding-bottom: 64px;
}
footer .white-blur-bg{
  padding: 40px 64px;
}
footer .navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  position: relative;
}
footer .navigation nav{
  display: flex;
  gap: 32px;
}
footer .navigation nav a{
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: var(--neutral700);
}
footer .navigation .social-links{
  display: flex;
  gap: 15px;
  position: absolute;
  right: 0;
}
footer .navigation .social-links a{
  display: flex;
}
footer .copyright{
  display: flex;
  justify-content: center;
}
footer .copyright span{
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--neutral700);
  opacity: 0.9;
}

/*NOT FOUND*/
.not-found{
  padding-bottom: 64px;
}
.not-found .numbers{
  margin-top: 144px;
  font-weight: 700;
  font-size: 150px;
  line-height: 225px;
  text-align: center;
  text-transform: uppercase;
  color: var(--neutral700);
}
.not-found .text{
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: var(--neutral700);
  opacity: 0.8;
  margin-top: -35px;
}
.not-found button{
  margin: 18px auto 0;
}


/*Home*/
/*First screen*/
.first-screen h1{
  font-weight: 700;
  font-size: 159px;
  line-height: 239px;
  text-align: center;
  text-transform: uppercase;
  color: var(--neutral700);
  margin-top: 200px;
}
.first-screen .first-screen-content{
  display: flex;
  margin: -22px auto 0;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 334px;
  max-width: 960px;
}
.first-screen .first-screen-content div{
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: var(--neutral700);
}
.first-screen .first-screen-content button{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16.5px 33px;
}
.first-screen .first-screen-content button img{
  margin-right: 9px;
}

/*About*/
.home-about{
  padding-bottom: 220px;
}
.services{
  padding-bottom: 183px;
}
.home-about h2,
.services h2,
.strong-points h2{
  margin-bottom: 35px;
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  text-transform: uppercase;
  color: var(--neutral700);
  padding-left: 48px;
}
.home-about .white-blur-bg{
  padding: 82px 70px 80px 60px;
  display: flex;
  gap: 26px;
}
.home-about .white-blur-bg .about-description{
  font-weight: 300;
  font-size: 25px;
  line-height: 42px;
  color: var(--neutral700);
  background: url('./assets/small-ellipse.png') no-repeat -3px 0;
  background-size: 48px;
  padding-left: 10px;
}

/*Service */
.services{
  background: url("./assets/big-ellipse.png") no-repeat center 221px;
  background-size: 684px;
}
.services .white-blur-bg{
  padding: 80px 70px 90px 0;
  display: flex;
  flex-direction: column;
  gap: 74px;
}
.services .service-section{
  display: flex;
  flex-direction: column;
  gap: 54px;
}
.services .service-section-title {
  padding: 25px 0 15px 70px;
  border-bottom: 6px solid #FFEE32;
  color: var(--neutral700);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  width: fit-content;
  word-break: break-word;
}
.services .service-section-list{
  display: flex;
  gap: 36px;
  padding-left: 70px;
}
.services .service{
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
}
.services .service h4{
  color: var(--neutral700);
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}
.services .service p{
  color: var(--neutral700);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  opacity: 0.7;
}

/*Strong-points*/
.strong-points{
  padding-bottom: 224px;
}
.strong-points .strong-points-list{
  display: flex;
  gap: 16px;
}
.strong-points .strong-point{
  padding: 36px 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.strong-points .strong-point h3{
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--neutral700);
  margin-bottom: 12px;
}
.strong-points .strong-point p{
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  opacity: 0.7;
  color: var(--neutral700);
  margin-bottom: 30px;
}
.strong-points .strong-point .icon-wrapper{
  width: 64px;
  height: 64px;
  background: var(--yellow);
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}


/*Our Services*/
.our-services{
  margin-bottom: 195px;
  margin-top: 130px;
}
.our-services h1{
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
  text-align: center;
  text-transform: uppercase;
  color: var(--neutral700);
  margin-bottom: 70px;
}
.our-services .our-services-list{
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 64px 70px  64px 0;
  max-width: 940px;
  margin: 0 auto;
}
.our-services .our-services-list h2{
  padding: 25px 0 12px 70px;
  border-bottom: 6px solid #FFEE32;
  color: var(--neutral700);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  width: fit-content;
  word-break: break-word;
}

.our-services .our-services-list .service{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 20px 0 20px 70px;
  margin-top: 40px;
}
.our-services .our-services-list .service h3{
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--neutral700);
  display: flex;
  align-items: center;
}
.our-services .our-services-list .service h3 span{
  display: block;
  width: 24px;
  height: 24px;
  background: var(--yellow);
  margin-right: 10px;
  border-radius: 50%;
}
.our-services .our-services-list .service div{
  font-size: 21px;
  font-weight: 400;
  line-height: 34px;
  color: var(--neutral700);
}
.our-services .our-services-list .service div p{
  margin-bottom: 8px;
}

.ball{
  background-image: url("./assets/big-ellipse.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  background-position: center;
  pointer-events: none;
}
.ball-s{
  width: 68px;
  height: 68px;
  top: 194px;
  right: -20px
}
.ball-m{
  width: 183px;
  height: 183px;
  top: 514px;
  left: -41px
}
.ball-l{
  width: 164px;
  height: 164px;
  top: 2160px;
  left: 61px
}
.ball-xl{
  width: 509px;
  height: 509px;
  top: 1129px;
  right: 116px;
}
.service-molecula{
  background-image: url("./assets/service-molecula.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  background-position: center;
  pointer-events: none;
  width: 651px;
  height: 651px;
  top: 2190px;
  left: 50%;
  transform: translateX(-50%);
}

/*Contact Us*/
.contact-us{
  padding: 130px 0 100px;
}
.contact-us h1{
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
  text-align: center;
  text-transform: uppercase;
  color: var(--neutral700);
  margin-bottom: 70px;
}
.contact-us .white-blur-bg{
  max-width: 940px;
  margin: 0 auto;
  padding: 82px 70px;
}
.contact-us .white-blur-bg h2{
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--neutral700);
  margin-bottom: 44px;
}
.contact-us .white-blur-bg .info-part{
  margin-bottom: 44px;
  font-weight: 300;
  font-size: 24px;
  line-height: 34px;
  color: var(--neutral700);
}
.contact-us .white-blur-bg .info-part:last-child{
  margin-bottom: 0;
}
.contact-us .white-blur-bg .info-part h3{
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: var(--neutral700);
}
.contact-us .white-blur-bg .info-part a{
  font-weight: 300;
  font-size: 24px;
  line-height: 34px;
  color: var(--neutral700);
  text-decoration: underline;
}
.contact-us .white-blur-bg .info-part button{
  margin-top: 16px;
}
.molecula{
  width: 695px;
  height: 695px;
  background-image: url("./assets/molecula.png");
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top:300px;
  left: 50%;
  transform: translateX(-50%);
  background-position: center;
  pointer-events: none;
}

@media screen and (max-width: 1100px) {
  header .header-inner {
    gap: 42px;
  }
}
@media screen and (max-width: 991px) {
  header, main, footer{
    padding: 0;
  }
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .container.s {
    padding-left: 10px;
    padding-right: 10px;
  }
  .bg.big-molecula{
    background: url("./assets/big-molecula-mobile.png") no-repeat top;
    background-size: cover;
  }
  .bg.small-molecula{
    display: none;
  }
  .white-blur-bg{
    background: linear-gradient(168.65deg, rgba(255, 255, 255, 0.9) -2.45%, rgba(255, 255, 255, 0.7) 102.09%);
    box-shadow: inset -5px -5px 0 rgba(255, 255, 255, 0.1), inset 5px 5px 4px rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(22.5px);
    border-radius: 20px;
  }

  /*Header*/
  header .header-inner {
    height: 84px;
    gap: unset;
  }
  header .logo {
    width: 132px;
  }
  header nav {
    display: none;
  }
  header .logo-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  header .additional-buttons {
    display: flex;
  }
  header.opened{
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: var(--light-grey);
    background-image: url("./assets/big-molecula-mobile-blured.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    overflow: hidden;
  }
  header.opened .header-inner {
    display: flex;
    flex-direction: column;
  }
  header.opened .logo-wrapper {
    margin: 30px 0 0;
  }
  header.opened nav {
    display: flex;
    flex-direction: column;
    margin-top: 159px;
    align-items: flex-start;
  }
  header.opened nav a {
    line-height: 24px;
  }
  header.opened .buttons-wrapper{
    display: flex;
    margin-top: auto;
    width: 100%;
    margin-bottom: 16px;
  }
  header.opened .buttons-wrapper button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16.5px 23.5px;
    font-size: 18px;
    line-height: 150%;
    white-space: nowrap;
    flex: 1;
  }
  header.opened .container,
  header.opened .header-inner{
    height: 100%;
  }

  /*Footer*/
  footer{
    padding-bottom: 10px;
  }
  footer .white-blur-bg{
    padding: 60px;
  }
  footer .navigation {
    margin-bottom: unset;
    flex-direction: column;
    align-items: center;
    gap: 44px;
  }
  footer .navigation nav{
    gap: 14px;
    flex-direction: column;
    align-items: center;
  }
  footer .navigation nav a{
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  footer .navigation .social-links{
    position: relative;
    right: unset;
  }
  footer .navigation .social-links a{
    display: flex;
  }
  footer .copyright{
    display: none;
  }

  /*NOT FOUND*/
  .not-found .numbers{
    margin-top: 74px;
    font-size: 90px;
    line-height: 135px;
  }
  .not-found .text{
    font-size: 16px;
    line-height: 28px;
    margin-top: -23px;
  }
  .not-found button{
    margin: 16px auto 0;
  }


  /*Home*/
  /*First screen*/
  .first-screen h1{
    font-size: 52px;
    line-height: 78px;
    margin-top: 39px;
    text-align: left;
  }
  .first-screen .first-screen-content{
    flex-direction: column;
    margin: 9px auto 0;
    gap: 20px;
    padding-bottom: 140px;
    align-items: flex-start;
  }
  .first-screen .first-screen-content div{
    font-size: 24px;
    line-height: 32px;
  }
  .first-screen .first-screen-content button{
    width: 100%;
    max-width: 462px;
  }
  .first-screen .first-screen-content button img{
    display: none;
  }

  /*About*/
  .home-about,
  .services{
    padding-bottom: 140px;
  }
  .home-about h2,
  .services h2,
  .strong-points h2{
    margin-bottom: 20px;
    font-size: 36px;
    line-height: normal;
    font-weight: 700;
    padding-left: 20px;
  }
  .home-about .white-blur-bg{
    padding: 40px 20px;
    flex-direction: column;
    gap: 24px;
  }
  .home-about .white-blur-bg .about-description{
    font-size: 18px;
    line-height: 27px;
    color: var(--neutral700);
    background: url('./assets/small-ellipse.png') no-repeat 0 -2px;
    background-size: 36px;
    padding-left: 10px;
  }

  /*Service */
  .services{
    background: url("./assets/big-ellipse.png") no-repeat center calc(100% - 54px);
    background-size: 306px;
  }
  .services .white-blur-bg{
    padding: 40px 20px;
    gap: 60px;
  }
  .services .service-section{
    gap: 34px;
  }
  .services .service-section-title{
    padding: 0 0 10px;
    font-size: 27px;
  }
  .services .service-section-list{
    flex-direction: column;
    gap: 40px;
    padding-left: 0;
  }
  .services .service{
    gap: 8px;
  }
  .services .service h4{
    font-size: 22px;
  }
  .services .service p{
    font-size: 16px;
    line-height: 140%;
  }

  /*Strong-points*/
  .strong-points{
    padding-bottom: 100px;
  }
  .strong-points .strong-points-list{
    flex-direction: column;
  }
  .strong-points .strong-point{
    padding: 30px 20px;
  }
  .strong-points .strong-point p{
    margin-bottom: 68px;
    font-size: 16px;
  }
  .strong-points .strong-point h3{
    font-size: 22px;
  }
  .strong-points .strong-point .icon-wrapper{
    margin-top: unset;
  }


  /*Our Services*/
  .our-services{
    margin-top: 60px;
    margin-bottom: 100px;
  }
  .our-services h1{
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 24px;
  }

  .our-services .our-services-list{
    gap: 70px;
    padding: 40px 20px;
  }
  .our-services .our-services-list h2{
    padding: 0 0 10px 0;
    font-size: 27px;
    font-weight: 700;
    line-height: 120%;
    width: fit-content;
    word-break: break-word;
  }

  .our-services .our-services-list .service{
    gap: 8px;
    padding: 0;
    margin-top: 34px
  }
  .our-services .our-services-list .service h3{
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    max-width: 200px;
  }
  .our-services .our-services-list .service h3 span{
    display: none;
  }
  .our-services .our-services-list .service div{
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    opacity: 0.7;
  }
  .our-services .our-services-list .service button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    margin-top: 8px;
  }
  .ball-s{
    top: 122px;
    right: -34px
  }

  .ball-m{
    width: 127px;
    height: 127px;
    top: 389px;
    left: -64px
  }
  .ball-l, .service-molecula{
    display: none;
  }
  .ball-xl{
    width: 260px;
    height: 260px;
    top: 792px;
    right: -46px
  }

  /*Contact Us*/
  .contact-us{
    padding: 60px 0 100px;
  }
  .contact-us h1{
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 24px;
  }
  .contact-us .white-blur-bg{
    padding: 40px 20px;
  }
  .contact-us .white-blur-bg h2{
    font-size: 28px;
    margin-bottom: 24px;
  }
  .contact-us .white-blur-bg .info-part{
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
  }
  .contact-us .white-blur-bg .info-part h3{
    font-size: 16px;
    line-height: 24px;
  }
  .contact-us .white-blur-bg .info-part a{
    font-size: 16px;
    line-height: 24px;
  }
  .contact-us .white-blur-bg .info-part button{
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  }
  .molecula{
    width: 347px;
    height: 347px;
    top:174px;
  }
}
@media screen and (max-width: 400px) {
  .our-services .our-services-list h2{
    display: flex;
    flex-direction: column;
  }
}
